import { Component } from 'react';
import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import ActivationForm from './activation/ActivationFormComponent';
import ActivationConfirmation from './activation/ActivationConfirmationComponent';
import ActivationComplete from './activation/ActivationCompleteComponent';
import { Redirect } from 'react-router';

export default class ActivationPage extends Component {

    state = {
        page: 1,
        companyName: "",
        crewingUserEmail: "",
        activationToken: "",
        loading: true,
        tokenNotFound: false
    }

    componentDidMount () {
        this.props.setPageName('Activate');
    }

    componentDidMount = () => {
        const token = new URLSearchParams(this.props.location.search).get("token")

        if (!token) {
            this.setState({
                loading: false,
                tokenNotFound: true
            })
        } else {
            axios.get(`v1/company/get?activation_token=${token}`)
                .then(res => {
                    const { data } = res;

                    this.setState({
                        ...this.state,
                        companyName: data.company_name,
                        crewingUserEmail: data.crewing_user_email,
                        loading: false,
                        activationToken: token
                    })
                }).catch(err => {
                    this.setState({
                        ...this.state,
                        error: true,
                        loading: false
                    })
                    ErrorHandler(err, this.props.history, () => {})
                })
        }
    }

    submit = () => {
        const { crewingUserEmail, activationToken, password } = this.state;

        const request = {
            activation_token: activationToken,
            crewing_user_email: crewingUserEmail,
            password
        }

        axios.post('v1/user/activate', request)
            .then(res => {
                const { status, statusText } = res;

                if (status === 200 || statusText === 'OK') {
                    this.goNext({
                        activationToken: null
                    });
                }
            }).catch(err => {
                ErrorHandler(err, this.props.history, () => {})
            })
    }

    goNext = (state) => {
        this.setState({
            ...this.state,
            ...state,
            page: this.state.page + 1
        })
    }

    goBack = () => {
        this.setState({
            ...this.state,
            page: this.state.page - 1
        })
    }

    content = () => {
        if (!this.state.loading) {
            const { companyName, crewingUserEmail, page } = this.state

            switch (page) {
                case 1:
                    return (
                        <ActivationForm
                            companyName={companyName}
                            crewingUserEmail={crewingUserEmail}
                            nextHandler={this.goNext}
                        />
                    );
                case 2:
                    return (
                        <ActivationConfirmation
                            onSubmit={this.submit}
                            backHandler={this.goBack}
                        />
                    )
                case 3:
                    return (
                        <ActivationComplete />
                    )
            }

        }
    }

    render() {
        if (this.state.tokenNotFound || this.state.error) {
            // TODO: 404 page
            return <Redirect to={'/404'} />
        }
        return (
            <div className="app-layout">
                {this.content()}
            </div>
        )
    }
}