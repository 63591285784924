import { Component } from 'react';
import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import { Redirect } from 'react-router';
import ContributionDetail from './contributionList/ContributionDetailComponent';
import ContributionList from './contributionList/ContributionListComponent';

export default class ContributionListPage extends Component {

    state = {
        contributionList : [],
        selectedContribution : null
    }

    componentDidMount () {
        this.props.setPageName('Manage Contributions');
        this.getContributionList()
    }

    getContributionList = () => {
        axios.get('v1/contribution/all')
        .then (res => {
                this.setState({contributionList : res.data, selectedContribution : null})
            }
        ).catch(err => {
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
            }
        )
    }

    gotoDetail = contribution => {
        this.setState({...this.state , selectedContribution: contribution})
    }

    gotoList = () => {
        this.setState({...this.state , selectedContribution: null})
    }

    handleDetailAction = ({contribution, actionType}) => {
        const {id} = contribution
        let url = ""
        switch(actionType) {
            case 'approve':
                url = 'v1/contribution/approve'
                break;
            default:
                url = 'v1/contribution/reject'
                break;
          }

        axios.post(url, {id})
        .then (res => {
                this.getContributionList()
            }
        ).catch(err =>
            ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
        )
    }

    handleDownload = contribution => {
        let extraUrl = '?id=' + contribution.id
        axios.get('v1/contribution/file' + extraUrl)
        .then (res => {
                const {file_exist, url} = res.data
                if (file_exist) {
                    const link = document.createElement('a')
                    link.href = url
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                } else {
                    alert("File not Found")
                }
            }
        ).catch(err =>
            ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
        )
    }

    render () {
        const {contributionList, selectedContribution} = this.state;
        const {user_role} = this.props.state
        if(!this.props.state.loggedIn) {
            return <Redirect to={'/login'}  />
        }
        return (
            <div className="app-layout">
                    <h2>Indonesian Seafarer Background Check</h2>
                    {user_role !== 'admin' ?
                            <div className="user-result">
                                <div> You are unauthorized to access this data</div>
                                <div className="bottom-filler"/>
                            </div>
                        :
                        selectedContribution ?
                        <ContributionDetail contribution={selectedContribution} downloadHandler={this.handleDownload} backHandler={this.gotoList} actionHandler={this.handleDetailAction}/> :
                        <ContributionList contributionList={contributionList} actionHandler={this.gotoDetail}/>
                    }
            </div>
        )
    }

}
