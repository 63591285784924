export default function ErrorHandler(err, history, logoutHandler) {
    const { error } = err.response.data;

    if (err.response.status === 500) {
        return alert('Server Error')
    }

    if (err.message === "Network Error") {
        alert("Network Error\n Please check your internet connection")
    } else if (error === "You have no privilege to access user data") {
        alert("You are not authorized to access this page")
    } else if (error.includes("E_INVALID_JWT_TOKEN") || error.includes("E_JWT_TOKEN_EXPIRED")) {
        alert("Session timed out")
        logoutHandler()
        history.push('/login')
    } else if (error === "Please contact administrator") {
        history.push('/inactive')
    } else if (error) {
        alert(error)
    } else {
        alert(err.message)
    }
}
