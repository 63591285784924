import { Component } from 'react';
import ErrorHandler from './ErrorHandler';
import SearchForm from './search/SearchFormComponent';
import SearchResult from './search/SearchResultComponent';
import axios from 'axios';
import { Redirect } from 'react-router';

export const DUMMY = {
    full_name: 'JOHN DOE',
    seafarer_id: '1234567890',
    nik: '3000001234560009'
};

const dummyobj = {
    "id":1,
    "nik":"3131010101990001",
    "full_name":"John Doe",
    "birth_place":"Jawa",
    "birth_date":"1974-01-01T00:00:00.000Z",
    "seafarer_id":"1234512341",
    "created_by":"john.doe@mailinator.com",
    "last_updated_by":"john.doe@mailinator.com",
    "created_at":"2022-03-22 04:00:03",
    "updated_at":"2022-03-22 04:00:03",
    "offenses":[
    {
        "offense_date":null,
        "category_name":"Violence",
        "category_description":null
    },
    {
        "offense_date":null,
        "category_name":"Violence",
        "category_description":null
    }
    ],
    ...DUMMY
};

export default class Search extends Component {

    state = {
        result: null,
        request: null
    }

    componentDidMount () {
        if (this.state.result === null) {
            this.props.setPageName('Search');
        } else {
            this.props.setPageName('Search Result');
        }
    }

    handleSearch = (request) => {
        const dummyMatch = request?.name?.toLowerCase() === DUMMY.full_name.toLowerCase() && (request.seafarer_id === DUMMY.seafarer_id || request.nik === DUMMY.nik);
        if (this.props.state.is_contributing || (!this.props.state.is_contributing && !dummyMatch)) {
            let extraUrl = '?name=' + request.name;
            if (request.seafarer_id)
                extraUrl += '&seafarer_id=' + request.seafarer_id;
            if (request.nik)
                extraUrl += '&nik=' + request.nik;
            axios.get('v1/person/search' + extraUrl)
            .then( res => {
                    this.setState({result: res.data, request: request});
                }
            ).catch( err =>
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
            )
        } else {
            this.setState({
                result: [dummyobj],
                request
            });
        }
    }

    gotoLogin = () => {
        this.props.logoutHandler()
        this.props.history.push('/login')
    }

    handleUnsearch = () => {
        this.setState({result: null, request: null});
    }

    render () {
        if(!this.props.state.loggedIn) {

            return <Redirect to={'/login'}  />
        }
        return (
            (this.state.result === null) ?
                <SearchForm state={this.props.state} searchHandler={this.handleSearch}/> :
                <SearchResult request={this.state.request} result={this.state.result} unsearchHandler={this.handleUnsearch}/>

        );
    }

}
