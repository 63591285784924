
export default function ContributionEntry ({index, contribution, actionHandler}) {    

    const getLogoClassName = contribution => {
        if (contribution.is_approved == null) return "user-icon yellow"
        if (contribution.is_approved) return "user-icon green"
        return "user-icon red"
    }

    const getLogo = contribution => {
        if (contribution.is_approved == null) return "fas fa-exclamation"
        if (contribution.is_approved) return "fas fa-check"
        return "fas fa-times"
    }

    const {num_of_entries, company_name} = contribution;

    return (
        <div className={(index % 2) == 0 ? 'user-entry even' : 'user-entry odd'} onClick={() => actionHandler(contribution)}>
            <div className="user-entry-data">
                <label className="user-label">{num_of_entries} entries</label>
                <label className="user-label bold">{company_name}</label>
            </div>
            <div className={getLogoClassName(contribution)}>
                <i className={getLogo(contribution)}/>
            </div>
            
        </div>            
    );
};