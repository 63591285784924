
export default function UserEntry ({index, user, actionHandler}) {    

    const getLogoClassName = user => {
        if (!user.is_approved) return "user-icon yellow"
        if (user.is_enabled) return "user-icon green"
        return "user-icon red"
    }

    const getLogo = user => {
        if (!user.is_approved) return "fas fa-exclamation"
        if (user.is_enabled) return "fas fa-check"
        return "fas fa-times"
    }

    const {fullname, company} = user;

    return (
        <div className={(index % 2) == 0 ? 'user-entry even' : 'user-entry odd'} onClick={() => actionHandler(user)}>
            <div className="user-entry-data">
                <label className="user-label">{fullname}</label>
                <label className="user-label bold">{company}</label>
            </div>
            <div className={getLogoClassName(user)}>
                <i className={getLogo(user)}/>
            </div>
            
        </div>            
    );
};