export default function ContributionTwo ({nextHandler}) {  

    return (
        <>
            <div className='contribution-step-2'>
                2. Fill the template with data
            </div>
            <div className='contribution-step-detail-2'>
                Guidelines for filling the template with data
            </div>  
            <div className='contribution-step-grid'>
                <div>
                    1.
                </div>
                <div>
                    Only update true and accurate data about crew who have been employed by your company.
                </div>
            </div>
            <div className='contribution-step-grid'>
                <div>
                    2.
                </div>
                <div>
                    Only upload serious offenses. The most common categories are:<br/><br/>
                    Drugs/Alcohol<br/>
                    Fraud/Forgery<br/>
                    Fuel Manipulation<br/>
                    Jump Ship<br/>
                    Provocator<br/>
                    Violence<br/>
                    Other Criminal Activity (theft, etc.)<br/><br/>
                    Please do NOT upload data relating to poor performance, personal matters, or other non-criminal reasons.
                </div>
            </div>
            <div className='contribution-step-grid'>
                <div>
                    3.
                </div>
                <div>
                    You must fill in at least NIK or Seafarer ID for every entry.
                </div>
            </div>
            <button className="button-primary" onClick={() => nextHandler()}>
                Next
            </button>
            <div className="bottom-filler"/>
        </>
    )
}


