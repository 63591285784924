import React from 'react';  

export default function ContributionThree ({actionHandler}) {  

    const hiddenFileInput = React.useRef(null);
        
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        actionHandler(fileUploaded)
    };

    return (
        <>
            <div className='contribution-step-2'>
                3. Upload File
            </div>
            <div className='contribution-step-detail'>
                Upload file .XLS or .XLSX (max 10 MB)
            </div>  
            <button className="button-mini-primary" onClick={handleClick}>
                Click to upload
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: 'none'}}
            />
            <div className="bottom-filler"/>
        </>
    )
}
