import React, { useState } from 'react';
import {Link} from "react-router-dom";

const BasicList = [
     {
         title: 'SEARCH',
         path: '/',
         cName:'dropdown-link'
     },
     {
         title: 'UPLOAD',
         path: '/contribution',
         cName:'dropdown-link'
     },
     {
         title: 'CHANGE PASSWORD',
         path: '/crewing-user/password/reset',
         cName: 'dropdown-link'
     }
 ]

const ContributorList = [
    ...BasicList,
 ]

const AdminList = [
    {
        title: 'SEARCH',
        path: '/',
        cName:'dropdown-link'
    },
    {
        title: 'MANAGE USERS',
        path: '/users',
        cName:'dropdown-link'
    },
    {
        title: 'MANAGE UPLOADS',
        path: '/contributionList',
        cName:'dropdown-link'
    },
    {
        title: 'CHANGE PASSWORD',
        path: '/crewing-user/password/reset',
        cName: 'dropdown-link'
    }
 ]

 export default function Dropdown({role, dropdownHandler}) {

   const getList = role => {
        switch(role) {
            case 'admin':
                return AdminList;
            case 'contributor':
                return ContributorList;
            default:
                return BasicList;
        }
    }

    return (<>
        <ul className='dropdown-menu'>
            {getList(role).map(item => {
                return (
                    <li key={item.title}>
                        <Link
                            className={item.cName}
                            to={item.path}
                            onClick={() => dropdownHandler()}>
                            {item.title}
                        </Link>
                    </li>
                )
            })}
        </ul>
    </>);
}