import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Companies from './companies/CompaniesComponent';
import CompanyDetail from './companies/CompanyDetailComponent';

export default class CompaniesPage extends Component {

    state = {
        companies: [],
        selectedCompanyId: null,
    }

    componentDidMount() {
        this.props.setPageName('Manage Users');
        this.getCompanies();
    }

    getCompanies() {
        axios.get('v1/company/get/all')
            .then(res => {
                this.setState({
                    companies: res.data
                })
            })
            .catch(err => {
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler());
            });
    }

    gotoCompanyDetail = companyId => {
        this.setState({
            ...this.state,
            selectedCompanyId: companyId
        });
    }

    gotoList = () => {
        this.setState({
            ...this.state,
            selectedCompanyId: null,
        })
    }

    approve = () => {
        const { selectedCompanyId } = this.state;

        const request = {
            company_id: selectedCompanyId
        };

        axios.post('v1/company/activate', request)
            .then(res => {
                this.getCompanies();
                this.gotoList();
            }).catch(err => {
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler());
            })
    }

    reject = () => {
        const { selectedCompanyId } = this.state;

        const request = {
            company_id: selectedCompanyId
        };

        axios.post('v1/company/reject', request)
            .then(res => {
                this.getCompanies();
                this.gotoList();
            }).catch(err => {
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler());
            })
    }

    renderNotAuthorized() {
        return (
            <div className="user-result">
                <div> You are unauthorized to access this data</div>
                <div className="bottom-filler" />
            </div>
        );
    }

    renderContent() {
        const { companies, selectedCompanyId } = this.state;

        if (selectedCompanyId) {
            return (
                <>
                    <CompanyDetail
                        company={companies.find(x => x.id === selectedCompanyId)}
                        backHandler={this.gotoList}
                        onApprove={this.approve}
                        onReject={this.reject}
                    />
                </>
            )
        }

        return (
            <div>
                <Companies
                    companies={companies}
                    actionHandler={this.gotoCompanyDetail}
                    showAll={true}
                />
            </div>
        );
    }

    render() {
        const { user_role } = this.props.state

        if (!this.props.state.loggedIn) {
            return <Redirect to={'/'} />
        }

        return (
            <div className="app-layout">
                <h2>Indonesian Seafarer Background Check</h2>
                <div className={"sub-title"}>
                    <label>Manage Users</label>
                </div>
                {
                    user_role !== 'admin' ? this.renderNotAuthorized() : this.renderContent()
                }
            </div>
        )
    }
}