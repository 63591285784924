import { Component } from 'react';
import OffenseEntry from './OffenseEntryComponent';

export default class SearchResult extends Component {

    componentWillUpdate() {
        this.processData();
    }

    componentWillMount() {
        this.processData();
    }

    handleUnsearch = e => {
        e.preventDefault();
        const request = {
            name: this.fullname,
            seafarer_id: this.seafaredId,
            nik: this.nik
        }
        this.props.searchHandler(request);
    }

    processData = () =>  {

        let requestData = this.props.request;
        this.name = "\""  + requestData.name + "\""
        this.sfid = (requestData.seafarer_id) ? "SFID   " + requestData.seafarer_id : ''
        this.nik = (requestData.nik) ? "NIK   " + requestData.nik : ''

        if (this.props.result) {
            this.entries = this.props.result.map(x => {
                return {
                    full_name: x.full_name,
                    offenses: x.offenses
                }
            });

            if (this.entries.length > 1) {
                this.entriesCount = this.entries.length + ' entries found.';
            } else {
                this.entriesCount = this.entries.length + ' entry found.';
            }
        } else {
            this.entriesCount = 'Not Found.';
            this.entries = [];
        }
    }

    render () {
        const disclaimerText =
        `Disclaimer: this service is purely informational, and does not
        bear any responsibility or liability whatsoever for the accuracy,
        completion or source of any and all content. Data is merely
        crowdsourced from multiple sources and is not verified or
        approved by this service. Users are advised to use their own
        discretion on interpreting the data.`
        return (
            <div className="app-layout" >
                <div className="disclaimer">
                        {disclaimerText}
                </div>

                <div className="search-result-header">

                    <div className="search-result-header-left">
                        <label className="search-result-header-title">Search result for</label>
                    </div>

                    <div className="search-result-header-right">
                        <div className="search-result-header-name">{this.name.toUpperCase()}</div>
                        { (this.sfid) ? (<div className="search-result-header-entry">{this.sfid}</div>): ""}
                        { (this.nik) ? (<div className="search-result-header-entry">{this.nik}</div>): ""}
                    </div>

                </div>

                <div className="offense-entry-count">
                    {this.entriesCount}
                </div>

                <div className="search-result-content">
                    {this.entries.map((entry, index) => (
                        <OffenseEntry key={index} offense={entry}/>
                    ))}
                </div>
                <button className="button-primary" onClick={() => this.props.unsearchHandler()}>
                    Do Another Search
                </button>
                <div className="bottom-filler"/>
            </div>
        );
    }
};
