import { Component } from 'react';
import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import { Redirect } from 'react-router';
import ContributionOne from './contribution/ContributionOneComponent';
import ContributionTwo from './contribution/ContributionTwoComponent';
import ContributionThree from './contribution/ContributionThreeComponent';
import ContributionResult from './contribution/ContributionResultComponent';
import AppContext from '../Context';
import {Link} from "react-router-dom";

export default class ContributionPage extends Component {
    static contextType = AppContext || {}
    state = {
        page: 0,
        uploadResult: null
    }

    componentDidMount() {
        this.props.setPageName('Contribution');
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.page && nextState.page !== this.state.page) {
            this.context.dispatch({
                navbarAction: (
                    <li className='nav-item'>
                        <Link className='nav-links' onClick={this.goBack}>
                            <i className="fas fa-arrow-left" />
                        </Link>
                    </li>
                )
            });
        } else if (!nextState.page && nextState.page !== this.state.page) {
            this.context.dispatch({
                navbarAction: null
            })
        }
    }

    componentWillUnmount() {
        this.context.dispatch({
            navbarAction: null
        })
    }

    goNext = () => {
        if (this.state.page <= 3) {
            this.setState({ ...this.state, page: this.state.page + 1 })
        }
    }

    goBack = () => {
        if (this.state.page >= 0) {
            this.setState({ ...this.state, page: this.state.page - 1 })
        }
    }

    handleDownloadTemplate = () => {
        axios.get('v1/contribution/template')
            .then(res => {
                const { file_exist, url } = res.data
                if (file_exist) {
                    const link = document.createElement('a')
                    link.href = url
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                } else {
                    alert("File not Found")
                }
            }
            ).catch(err =>
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
            )
    }

    handleUpload = file => {
        if (!file) {
            return;
        }

        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return alert('File not supported')
        }
        if (file.size > 10000000) {
            return alert('File size max 10 mb')
        }

        const formData = new FormData();
        formData.append(
            "import_file",
            file,
            file.name
        );
        axios.post('v1/contribution/upload', formData)
            .then(res => {
                const { data } = res;
                if (data.num_of_errors === 0) {
                    alert('Upload success!');
                    this.props.onUploadContributionSuccess();
                    this.props.history.push({
                        pathname: '/'
                    });
                }

                this.setState({ ...this.state, page: 3, uploadResult: res.data });
            }
            ).catch(err => {
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
            })
    }

    content = () => {
        switch (this.state.page) {
            case 0:
                return <ContributionOne actionHandler={this.handleDownloadTemplate} nextHandler={this.goNext} />
            case 1:
                return <ContributionTwo nextHandler={this.goNext} />
            case 2:
                return <ContributionThree actionHandler={this.handleUpload} />
            case 3:
                return <ContributionResult result={this.state.uploadResult} actionHandler={this.handleUpload} />
        }
    }

    render() {
        if (!this.props.state.loggedIn) {
            return <Redirect to={'/login'} />
        }
        return (
            <div className="app-layout">
                <h2>Indonesian Seafarer Background Check</h2>
                {this.content()}

            </div>
        )
    }

}
