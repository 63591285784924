import React, { Component } from 'react';
import axios from 'axios';
import RegisterProgressStep from './RegisterProgressStep';

export default class SectionThreeComponent extends Component {

    state = {
        crewingUserEmail: "",
        crewingUserEmailError: ""
    }

    handleContinue = e => {
        e.preventDefault();
        if (this.validateContinue()) {
            const { companyId } = this.props;
            const { crewingUserEmail } = this.state;

            const request = {
                company_id: companyId,
                crewing_user_email: crewingUserEmail.trim(),
                created_by: 'WEB'
            }

            axios.post('v1/company/section_3', request)
                .then(res => {
                    this.setState({ ...this.state, companyId: res.data.company_id })
                    this.props.continueHandler(this.state);
                }).catch(err => alert(err?.response?.data?.error || err.message))
        }
    }

    handleToggleButton = role => {
        this.setState({ ...this.state, role })
    }

    validateContinue = () => {
        const { crewingUserEmail } = this.state;
        let error = 0;

        let crewingUserEmailError = "";
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!crewingUserEmail || !crewingUserEmail.trim()) {
            crewingUserEmailError = "Email address cannot be empty"
            error++;
        } else if (!regex.test(crewingUserEmail)) {
            crewingUserEmailError = "Invalid email address format"
            error++;
        }

        this.setState({ ...this.state, crewingUserEmailError });
        if (!error) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { crewingUserEmail, crewingUserEmailError } = this.state;

        return (
            <>
                <form onSubmit={this.handleContinue}>
                    <div className="form-inner">
                        <div className="form-title-left">Register for a new account</div>
                        <RegisterProgressStep activeStep={3} />
                        <div className="form-group">
                            <label>CREWING USER EMAIL</label>
                            <label>Please enter the email address of the employee who will be using this crew checking service</label>
                            <input type="text" name="crewingUserEmail" id="crewingUserEmail"
                                value={crewingUserEmail}
                                onChange={e => this.setState({ ...this.state, crewingUserEmail: e.target.value })} />
                            {(crewingUserEmailError) ? (<div className="error">{crewingUserEmailError}</div>) : ""}
                        </div>

                    </div>
                    <input className="button-primary" type="submit" value="Next" />
                    <div className="bottom-filler" />
                </form>
            </>
        )
    }
}