import React, { Component } from 'react';
import WarningComponent from './../common/WarningComponent';

export default class ActivationFormComponent extends Component {

    state = {
        email: "",
        password: "",
        confirmationPassword: "",
        emailError: "",
        passwordError: "",
        confirmationPasswordError: "",
        showEmailWarning: false
    }

    handleContinue = e => {
        e.preventDefault();
        if (this.validateContinue()) {
            this.props.nextHandler(this.state);
        }
    }

    validateContinue = () => {
        const { email, password, confirmationPassword } = this.state;
        const { crewingUserEmail } = this.props;

        let error = 0;

        let emailError = "";
        let showEmailWarning = false;
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email || !email.trim()) {
            emailError = "Email should not be empty";
            error++;
        } else if (!regex.test(email)) {
            emailError = "Invalid email address format";
            error++;
        } else if (email !== crewingUserEmail) {
            showEmailWarning = true;
            error++;
        }

        let passwordError = "";
        let confirmationPasswordError = "";
        if (!password || password.trim().length < 8) {
            passwordError = "Password should at least have 8 characters";
            error++;
        } else if (!confirmationPassword || confirmationPassword.localeCompare(password)) {
            confirmationPasswordError = "Password does not match";
            error++;
        }

        this.setState({ ...this.state, showEmailWarning, emailError, passwordError, confirmationPasswordError });
        if (!error) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { email, password, confirmationPassword, emailError, passwordError, confirmationPasswordError, showEmailWarning } = this.state;
        const { companyName } = this.props;

        return (
            <form onSubmit={this.handleContinue}>
                <div className="form-inner">
                    <div className="form-title-left">Activate your account</div>
                    <div className="form-header lato">
                        Please activate the account for
                        <div className="activation-form-company-name">{companyName}</div>
                        This service allows you to do a background check on any Indonesian seafarer, based on shared information from other companies and employers.
                    </div>

                    <div className="form-group">
                        <label>EMAIL</label>
                        <input type="text" name="email" id="email"
                            value={email}
                            onChange={e => this.setState({ ...this.state, email: e.target.value })} />
                        {(emailError) ? (<div className="error">{emailError}</div>) : ""}
                    </div>

                    <WarningComponent
                        show={showEmailWarning}
                        message={`Please use the same email address
                            that received the invitation to
                            activate this account.
                            This is the Crewing User Email
                            submitted during registration.`}
                    />

                    <div className="form-group">
                        <label>CREATE PASSWORD</label>
                        <input type="text" name="password" id="password"
                            value={password}
                            type="password"
                            onChange={e => this.setState({ ...this.state, password: e.target.value })} />
                        {(passwordError) ? (<div className="error">{passwordError}</div>) : ""}
                    </div>

                    <div className="form-group">
                        <label>RE-ENTER PASSWORD</label>
                        <input type="text" name="confirmationPassword" id="confirmationPassword"
                            value={confirmationPassword}
                            type="password"
                            onChange={e => this.setState({ ...this.state, confirmationPassword: e.target.value })} />
                        {(confirmationPasswordError) ? (<div className="error">{confirmationPasswordError}</div>) : ""}
                    </div>

                </div>
                <input className="button-primary" type="submit" value="Next" />
                <div className="bottom-filler" />
            </form>
        )
    }
}