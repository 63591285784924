
export default function Inactive ({state}) { 
    return (
        <div className="app-layout">
            <h2>Indonesian Seafarer Background Check</h2>
            <div className='information-layout'>
                <h4>ACCOUNT INACTIVE</h4>
                <h5>your account has not been approved yet <br/>
                access is usually granted within 3 business days <br/> <br/>
                please contact administrator for more information</h5>
                <div className="bottom-filler"/>
            </div>
        </div>           
    );
};