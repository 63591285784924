import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ActivationCompleteComponent extends Component {

    render() {
        return (
            <form>
                <div className="form-inner">
                <div className="form-title-left">Account Activated</div>
                    <div className="form-header">
                        Congrats, your company’s account has been activated! You can now return to the home page and log in.
                    </div>
                </div>
                <div className="activation-complete-link">
                    <Link to="/">RETURN TO HOME</Link>
                </div>
            </form>
        );
    }
}