import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';

export default class Login extends Component {

     componentDidMount () {
         this.props.setPageName('Login');
     }

    state = {
        emailError: "",
        passwordError: ""
    }

    handleRedirectToRegister = e => {
        this.props.history.push({
            pathname: `/register`
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.validate())
            this.login();
    }

    redirectToForgotPassword = () => {
        this.props.history.push({
            pathname: 'crewing-user/forgot-password'
        })
    }

    validate = () => {
        let error = 0;

        let emailError = "";
        if (!this.email) {
            emailError = "email should not be empty";
            error++;
        }

        let passwordError = "";
        if (!this.password) {
            passwordError = "password should not be empty";
            error++;
        }
        if (!error) {
            return true;
        } else {
            this.setState({emailError, passwordError});
            return false;
        }
    }

    login = () => {
        const request = {
            email: this.email,
            password: this.password
        }
        this.error = "";

        axios.post('v1/user/login', request)
        .then (res => {
                axios.defaults.headers.common['Authorization'] = 'bearer ' + res.data.token;
                this.props.loginHandler(this.email, res.data);
            }
        ).catch(err => {
            let passwordError = "Wrong email or password";
            if (err.message === "Network Error")
                passwordError = err.message;
            this.setState({emailError: "", passwordError});
            }
        )
    }

    render () {
        if(this.props.state.loggedIn) {
            return <Redirect to={this.props.state?.user_role === 'admin' ? '/users' : '/'}  />
        }
        const {emailError, passwordError} = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <h2>Indonesian Seafarer Background Check</h2>
                <div className="form-inner">

                    <div className="form-group">
                        <label>EMAIL</label>
                        <input type="text" name="email" id="email"
                        onChange={e => this.email = e.target.value}/>
                        {(emailError) ? ( <div className="error">{emailError}</div>) : ""}
                    </div>

                    <div className="form-group">
                        <label>PASSWORD</label>
                        <input type="password" name="password" id="password"
                        onChange={e => this.password = e.target.value}/>
                        {(passwordError) ? ( <div className="error">{passwordError}</div>) : ""}
                    </div>

                </div>
                <input className="button-primary" type="submit" value="Login"/>
                <button className="button-secondary" onClick={() => this.redirectToForgotPassword()}>
                    FORGOT PASSWORD?
                </button>
                <button className="button-secondary" onClick={() => this.handleRedirectToRegister()}>
                    CREATE NEW ACCOUNT
                </button>
                <div className="bottom-filler"/>
            </form>
        )
    }
}