import axios from "axios"
import { useEffect, useState } from "react"
import CompanyEntry from "./EntryComponent"
import dayjs from 'dayjs';

export default function CompanyDetail({ company, onApprove, onReject, backHandler }) {
    const [statusHistory, setStatusHistory] = useState(null);

    useEffect(() => {
        axios.get('/v1/company/status-history', {
            params: {
                company_id: company.id
            }
        })
            .then((res) => {
                setStatusHistory(res.data);
            })
            .catch(() => {
                setStatusHistory([]);
                alert('Fail to get detail data');
            })
    }, []);

    return (
        <>
            <CompanyEntry
                key={company.id}
                index={2}
                title={company.representative_full_name}
                subtitle={company.company_name}
                isActive={company.is_active}
                actionHandler={() => {}}
            />
            <div className='contribution-detail'>
                Company NPWP <br />
                { company.company_npwp } <br /><br />
                Job Title / Position <br />
                { company.representative_job_title } <br /><br />
                Email <br />
                <a className="bold" href={`mailto:${company.representative_email_address}`}>{ company.representative_email_address }</a> <br /><br />
                Crewing User Email <br />
                <a className="bold" href={`mailto:${company.crewing_user_email}`}>{ company.crewing_user_email }</a> <br />
            </div>

            {statusHistory ? <div className="mt-4 pl-6">
                {statusHistory.length > 0 && <p className="font-bold mb-2 text-lg">Status History</p>}
                {statusHistory.map((status, index) => (
                    <div className={`relative border-l-[2px] border-l-grey-600 ${index !== statusHistory.length - 1 ? 'mb-3' : ''} ml-2 pl-5`} key={index}>
                        <span className="w-[20px] h-[20px] border-4 border-neutral-50 bg-stone-500 block rounded-full absolute left-0 -translate-x-1/2" />
                        <p>{status.status}</p>
                        <p>{dayjs(status.created_at).format('DD MMM YYYY')}</p>
                    </div>
                ))}
            </div> : (
                <p className="mt-4 pl-6">Loading data...</p>
            )}

            {!company.is_active ?
                <div className='category-button-area'>
                    <button className='button-ok' onClick={() => onApprove()}>APPROVE</button>
                    <button className='button-error' onClick={() => onReject()}>REJECT</button>
                </div> : ""}
            <button className="button-secondary bold" onClick={() => backHandler()}>
                Back
            </button>
        </>
    )
}
