import axios from 'axios';
import { Component } from 'react';
import ErrorHandler from './ErrorHandler';

export default class ResetPasswordPage extends Component {

    state = {
        crewingUserEmail: "",
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
        newPasswordError: "",
        reNewPasswordError: "",
        token: ""
    }

    componentDidMount = () => {
        this.props.setPageName('Reset Password');
        const token = new URLSearchParams(this.props.location.search).get("token")

        if (!token) {
            const { crewingUserEmail } = this.props.state;
            const { logoutHandler } = this.props;

            if (!crewingUserEmail) return logoutHandler();

            this.setState({
                ...this.state,
                crewingUserEmail
            });
        } else {
            axios.get(`v1/user/email?reset_password_token=${token}`)
                .then(res => {
                    this.setState({
                        ...this.state,
                        token,
                        crewingUserEmail: res.data.crewing_user_email
                    })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        alert('Token has been revoked');
                        this.props.history.push('/');
                    } else {
                        ErrorHandler(err, this.props.history, () => { })
                    }
                })
        }
    }

    validate = () => {
        const { newPassword, reNewPassword } = this.state;
        let error = 0;

        let newPasswordError = "";
        let reNewPasswordError = "";
        if (!newPassword || newPassword.trim().length < 8) {
            newPasswordError = "Password should at least have 8 characters";
            error++;
        } else if (!reNewPassword || reNewPassword.localeCompare(newPassword)) {
            reNewPasswordError = "Password does not match";
            error++;
        }

        this.setState({ ...this.state, newPasswordError, reNewPasswordError });
        if (!error) {
            return true;
        } else {
            return false;
        }
    }

    submit = e => {
        e.preventDefault();
        if (!this.validate()) return;

        if (this.state.token) {
            const { token, newPassword } = this.state;

            const request = {
                reset_password_token: token,
                password: newPassword
            };

            axios.post('v1/user/forgot-password', request)
                .then(res => {
                    alert('Your password has been set successfully');
                    this.props.history.push('/');
                }).catch(err => {
                    ErrorHandler(err, this.props.history, () => { })
                });
        } else {
            const { oldPassword, newPassword } = this.state;

            const request = {
                old_password: oldPassword,
                new_password: newPassword
            };

            axios.post('v1/user/password/change', request)
                .then(res => {
                    alert('Your password has been set successfully');
                    this.props.logoutHandler();
                    this.props.history.push('/');
                }).catch(err => {
                    console.log('err', err)
                    if (err.response && err.response.status && err.response.status === 401) {
                        alert("Old password does not match");
                    } else {
                        ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
                    }
                });
        }
    }

    render() {
        const { crewingUserEmail, oldPassword, newPassword, reNewPassword, newPasswordError, reNewPasswordError, token } = this.state;

        return (
            <div className="app-layout">
                <form onSubmit={this.submit}>
                    <div className="form-inner">
                        <div className="form-title-left">Reset your password</div>
                        <div className="form-group">
                            <p>User Email: <b>{crewingUserEmail}</b></p>
                        </div>
                        {
                            !token && <div className="form-group">
                            <div>
                                <label>OLD PASSWORD</label>
                                <input
                                    type="password"
                                    name="oldPassword"
                                    id="oldPassword"
                                    value={oldPassword}
                                    onChange={e => this.setState({ ...this.state, oldPassword: e.target.value })}
                                />
                            </div>
                        </div>
                        }
                        <div className="form-group">
                            <div>
                                <label>CREATE NEW PASSWORD</label>
                                <input
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={e => this.setState({ ...this.state, newPassword: e.target.value })}
                                />
                                {newPasswordError && (<div className="error">{newPasswordError}</div>)}
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <label>RE-ENTER PASSWORD</label>
                                <input
                                    type="password"
                                    name="reNewPassword"
                                    id="reNewPassword"
                                    value={reNewPassword}
                                    onChange={e => this.setState({ ...this.state, reNewPassword: e.target.value })}
                                />
                                {reNewPasswordError && (<div className="error">{reNewPasswordError}</div>)}
                            </div>
                        </div>
                    </div>
                    <input
                        className="button-primary"
                        type="submit"
                        value="Next"
                        disabled={newPassword.trim() && reNewPassword.trim() ? false : true}
                    />
                    <div className="bottom-filler" />
                </form>
            </div>
        )
    }
}