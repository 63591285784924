import UserEntry from './UserEntryComponent';

export default function UserList ({userList, actionHandler}) {  

    const basicList = userList.filter(user => user.role === 'basic')
    const contributorList = userList.filter(user => user.role === 'contributor')

    const pendingCount = userList.filter(user => !user.is_approved).length

    const getPendingCountString = () => {
        if (pendingCount === 0) return "no pending request"
        if (pendingCount === 1) return "1 pending request"
        return pendingCount + " pending requests"
    }

    return (
        <>
            <div className='pending-count'>
                <div className='pending-count-content'>
                    You have
                </div>
                <div className='pending-count-content bold'>
                    {getPendingCountString()}
                </div>
            </div>
            {basicList.length > 0 && <div className="user-result">
                <div className="user-result-header">
                    BASIC USER
                </div>
                {basicList.map((user, index) => 
                    <UserEntry key={user.user_id} index={index} user={user} actionHandler={actionHandler}/>)}
            </div>}
            {contributorList.length > 0 && <div className="user-result">
                <div className="user-result-header">
                    CONTRIBUTOR
                </div>
                {contributorList.map((user, index) => 
                    <UserEntry key={user.user_id} index={index} user={user} actionHandler={actionHandler}/>)}
            </div>}
            <div className="bottom-filler"/>
        </>
    )
}


