import React from 'react';
import WarningComponent from '../common/WarningComponent'
import '../common/semantic.css'

export default function ContributionResult ({result, actionHandler}) {

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        actionHandler(fileUploaded)
    };

    const getNameList = errorType => {
        if (errorType.name) return ""
        return errorType.append(", ")
    }

    return (
        result.num_of_errors === 0 ?
        <>
            <div className='contribution-step-2'>
                Upload Success
            </div>
            <div className='contribution-step-detail'>
                Thank You.<br/>Your Contribution has been added to our queue list.
            </div>
            <button className="button-mini-primary" onClick={handleClick}>
                Upload Another Response
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: 'none'}}
            />
            <div className="bottom-filler"/>
        </> :
        <>
            <div className='contribution-step-2'>
                3. Upload File
            </div>
            <div className='contribution-step-detail'>
                Upload file .XLS or .XLSX (max 10 MB)
            </div>
            <button className="button-mini-secondary" onClick={handleClick}>
                Click to upload again
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: 'none'}}
            />
            <br />
            {
                result.num_of_errors > 0 && <>
                    <WarningComponent
                        show={result.num_of_errors > 0}
                        message={`Upload failed. Please correct the errors below and try again.`}
                    />
                    <br />
                    <div className="contribution-upload-error-explanation">
                        <b>{result.num_of_errors} errors found.</b><br/><br/>
                        <b>Your contribution has not been submitted. </b>
                        Please correct the errors in your file and try again.
                        If you experience any difficulties please contact us at <a class="mailto" href="mailto:seafarercollective@gmail.com"><b>seafarercollective@gmail.com</b></a>.
                    </div>

                    <div className="contribution-upload-error-detail">
                        <b>Summary of Errors Found</b>
                    </div>
                    {result.summary.map((errorType) =>
                        <div className="contribution-upload-error-detail">{errorType.count} entries have {errorType.error}<br/> {getNameList(errorType)}</div>)}
                </>
            }
            <div className="bottom-filler"/>
        </>
    )
}
