import { Component } from 'react';
import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import { Redirect } from 'react-router';   
import UserDetail from './user/UserDetailComponent';
import UserList from './user/UserListComponent';

export default class User extends Component {

    state = {
        userList : [],
        selectedUser : null
    }

    componentDidMount () {
        this.props.setPageName('Manage Users');
        this.getApproveList()
    }
    
    getApproveList = () => {        
        axios.get('v1/user/all', {})
        .then (res => {
                this.setState({userList : res.data, selectedUser : null})
            }
        ).catch(err => {
                ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
            }
        )
    }

    gotoDetail = user => {
        this.setState({...this.state , selectedUser: user})
    }

    gotoList = () => {
        this.setState({...this.state , selectedUser: null})
    }

    handleDetailAction = ({user, actionType}) => {
        const {user_id} = user
        let url = ""
        switch(actionType) {
            case 'approve':
                url = 'v1/user/approve'
                break;
            case 'reject':
                url = 'v1/user/reject'
                break;
            case 'enable':
                url = 'v1/user/enable'
                break;
            default:
                url = 'v1/user/disable'
          }

        axios.post(url, {user_id})
        .then (res => {
                this.getApproveList()
            }
        ).catch(err => 
            ErrorHandler(err, this.props.history, () => this.props.logoutHandler())
        )
    }

    render () {
        const {userList, selectedUser} = this.state;
        const {user_role} = this.props.state
        if(!this.props.state.loggedIn) {
            return <Redirect to={'/login'}  />
        }
        return (
            <div className="app-layout">
                    <h2>Indonesian Seafarer Background Check</h2>
                    {user_role !== 'admin' ?
                            <div className="user-result">
                                <div> You are unauthorized to access this data</div>
                                <div className="bottom-filler"/>
                            </div>
                        : 
                        selectedUser ? 
                        <UserDetail user={selectedUser} backHandler={this.gotoList} actionHandler={this.handleDetailAction}/> :
                        <UserList userList={userList} actionHandler={this.gotoDetail}/>
                    }
            </div>
        )
    }

}
