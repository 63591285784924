import React, { Component } from 'react';
import axios from 'axios';
import RegisterProgressStep from './RegisterProgressStep';

export default class SectionFourComponent extends Component {

    state = {
        fullNameConfirmation: "",
        fullNameConfirmationError: ""
    }

    handleContinue = e => {
        e.preventDefault();
        if (this.validateContinue()) {
            const { companyId } = this.props;

            const request = {
                company_id: companyId,
                created_by: 'WEB'
            }

            axios.post('v1/company/section_4', request)
                .then(res => {
                    this.setState({ ...this.state, companyId: res.data.company_id })
                    this.props.continueHandler(this.state);
                }).catch(err => alert(err?.response?.data?.error || err.message))
        }
    }

    handleToggleButton = role => {
        this.setState({ ...this.state, role })
    }

    validateContinue = () => {
        const { fullNameConfirmation } = this.state;
        const { fullName } = this.props;
        let error = 0;

        let fullNameConfirmationError = "";
        if (!fullNameConfirmation || fullNameConfirmation.trim().length < 3) {
            fullNameConfirmationError = "Name should at least have 3 letters";
            error++;
        } else if (!fullNameConfirmation.match(/^[a-zA-Z\s]+$/)) {
            fullNameConfirmationError = "Name should not contain any special character or number";
            error++;
        } else if (fullNameConfirmation?.toLowerCase().trim().localeCompare(fullName?.toLowerCase())) {
            fullNameConfirmationError = "Name must be the same as the name in the registration form";
            error++;
        }

        this.setState({ ...this.state, fullNameConfirmationError });
        if (!error) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { fullName, jobTitle, companyName, companyNpwp, emailAddress, crewingUserEmail } = this.props;
        const { fullNameConfirmation, fullNameConfirmationError } = this.state;

        return (
            <form onSubmit={this.handleContinue}>
                <div className="form-inner">
                <div className="form-title-left">Register for a new account</div>
                    <RegisterProgressStep activeStep={4} />
                    <div className="form-group">
                        <p>As this is a crowdsourced service, every contributor relies on each other to ensure the veracity and accuracy of their own data.</p>
                        <p>Please carefully read the following and confirm all information is correct before proceeding.</p>
                        <p>Acknowledge at the bottom to complete your registration.</p>

                        <table>
                            <tr>
                                <td>COMPANY NAME</td>
                                <td className="value">{companyName}</td>
                            </tr>
                            <tr>
                                <td>COMPANY NPWP</td>
                                <td className="value">{companyNpwp}</td>
                            </tr>
                            <tr>
                                <td>JOB ROLE</td>
                                <td className="value">{jobTitle}</td>
                            </tr>
                            <tr>
                                <td>FULL NAME</td>
                                <td className="value">{fullName}</td>
                            </tr>
                            <tr>
                                <td>EMAIL</td>
                                <td className="value">{emailAddress}</td>
                            </tr>
                            <tr>
                                <td>CREWING USER EMAIL</td>
                                <td className="value">{crewingUserEmail}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="form-group">
                        <p className="subtitle">Declaration of Honesty</p>
                    </div>
                    <div className="form-group">
                        <p>I, <b>{fullName}</b>, as {jobTitle} in <b>{companyName}</b>, declare that we will not upload any false information or data about any individuals, and will make reasonable effort to ensure that any data submitted or uploaded to this service will be truthful and accurate.</p>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="fullNameConfirmation"
                            id="fullNameConfirmation"
                            value={fullNameConfirmation.toUpperCase()}
                            placeholder="Type Your Full Name Here"
                            onChange={e => this.setState({ ...this.state, fullNameConfirmation: e.target.value })} />
                        {(fullNameConfirmationError) ? (<div className="error">{fullNameConfirmationError}</div>) : ""}
                    </div>

                </div>
                <input className="button-primary" type="submit" value="Complete Registration" />
                <div className="bottom-filler" />
            </form>
        )
    }
}