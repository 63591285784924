import ContributionEntry from './ContributionEntryComponent';

export default function ContributionList ({contributionList, actionHandler}) {  

    const contributionListFiltered = contributionList.filter(contribution => contribution.is_approved == null || contribution.is_approved)

    const pendingCount = contributionListFiltered.filter(contribution => !contribution.is_approved).length

    const getContributionsCountString = () => {
        if (pendingCount === 0) return "no pending contribution"
        if (pendingCount === 1) return "1 pending contribution"
        return pendingCount + " pending contributions"
    }

    return (
        <>
            <div className='pending-count'>
                <div className='pending-count-content'>
                    You have
                </div>
                <div className='pending-count-content bold'>
                    {getContributionsCountString()}
                </div>
            </div>
            {contributionListFiltered.length > 0 && <div className="user-result">
                <div className="user-result-header">
                    CONTRIBUTION LIST
                </div>
                {contributionListFiltered.map((contribution, index) => 
                    <ContributionEntry key={contribution.id} index={index} contribution={contribution} actionHandler={actionHandler}/>)}
            </div>}
            <div className="bottom-filler"/>
        </>
    )
}


