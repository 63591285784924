import { Component } from 'react';

export default class RequestReset extends Component {
    state = {
        crewingUserEmail: ''
    }

    onSubmit = e => {
        e.preventDefault();

        if (!this.validate()) return;

        const { crewingUserEmail } = this.state;
        const { onSubmit } = this.props;

        onSubmit(crewingUserEmail);
    }

    validate() {
        const { crewingUserEmail } = this.state;

        let crewingUserEmailError = "";
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!crewingUserEmail || !crewingUserEmail.trim()) {
            crewingUserEmailError = "Email address cannot be empty"
        } else if (!regex.test(crewingUserEmail)) {
            crewingUserEmailError = "Email address format is wrong"
        }

        this.setState({ ...this.state, crewingUserEmailError })

        if (!crewingUserEmailError) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { crewingUserEmail, crewingUserEmailError } = this.state;

        return (
            <form onSubmit={this.onSubmit}>
                <div className="form-inner">
                    <div className="form-title-left">Forgot password?</div>
                    <div className="form-group">
                        <p>If you forgot your password, enter your email
                            address and we will send you a link to reset your password.</p>
                    </div>
                    <div className="form-group">
                        <div>
                            <label>EMAIL</label>
                            <input
                                type="text"
                                name="crewingUserEmail"
                                id="crewingUserEmail"
                                value={crewingUserEmail}
                                onChange={e => this.setState({ ...this.state, crewingUserEmail: e.target.value })}
                                placeholder="Type Your Crewing User Email Here"
                            />
                            {crewingUserEmailError && (<div className="error">{crewingUserEmailError}</div>)}
                        </div>
                    </div>
                </div>
                <input
                    className="button-primary"
                    type="submit"
                    value="Confirm"
                    disabled={crewingUserEmail.trim() ? false : true}
                />
                <div className="bottom-filler" />
            </form>
        )
    }
}