import axios from 'axios';
import { Component } from 'react';
import RequestReset from './forgotpassword/RequestResetComponent';

export default class ForgotPasswordPage extends Component {

    componentDidMount() {
        this.props.setPageName('Forgot Password');
    }

    submit = crewingUserEmail => {
        const request = {
            crewing_user_email: crewingUserEmail
        };

        axios.post('v1/user/forgot-password/request', request)
            .then(res => {
                alert('Link to reset your password has been sent to your email address');
            }).catch(err => {
                alert('Something is wrong');
            });
    }

    render() {
        return (
            <div className="app-layout">
                <RequestReset
                    onSubmit={this.submit}
                />
            </div>
        );
    }
}