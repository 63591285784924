import React, { Component } from 'react';

export default class WarningComponent extends Component {
    render() {
        if (this.props.show) {
            return (
                <div
                    className="form-group"
                >
                    <div
                        className="form-warning"
                    >
                        <div className="icon">
                            <i className="fas fa-exclamation-circle" />
                        </div>
                        <div>
                            <label>{this.props.message}</label>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }
}