import { Component } from 'react';
import { Link } from "react-router-dom";
import { DUMMY } from '../Search';

export default class SearchForm extends Component {

    state = {
        nameError: "",
        uniqueIdError: "",
        seafarerError: "",
        nikError: ""
    }

    handleSubmit = e => {
        e.preventDefault();

        if (this.validate()) {
            const request = {
                name: this.fullname,
                seafarer_id: this.seafarerId,
                nik: this.nik
            }
            this.props.searchHandler(request);
        }
    }

    validate = () => {
        let error = 0;

        let nameError = "";
        if (!this.fullname || this.fullname.trim().length < 3) {
            nameError = "name should at least have 3 letters";
            error++;
        } else if (!this.fullname.match(/^[a-zA-Z\s]+$/)) {
            nameError = "name should not contain any special character or number";
            error++;
        }
        let uniqueIdError = false;
        let seafarerError = "";
        let nikError = "";

        if (!this.seafarerId && !this.nik) {
            uniqueIdError = true;
            error++;
        } else {
            if (this.seafarerId && !this.seafarerId.match(/^[0-9]+$/)) {
                seafarerError = "seafarer id should only contain numbers";
                error++;
            }

            if (this.nik && !this.nik.match(/^[0-9]+$/)) {
                nikError = "nik should only contain numbers";
                error++;
            }
        }

        if (!error) {
            return true;
        } else {
            this.setState({nameError, uniqueIdError, seafarerError, nikError});
            return false;
        }
    }

    render () {
        const { nameError, uniqueIdError, seafarerError, nikError} = this.state;
        const { is_contributing } = this.props.state;

        return (
            <form onSubmit={this.handleSubmit}>
                    <h2>Indonesian Seafarer Background Check</h2>
                    {this.props.state.is_contributing || this.props.state.user_role === "admin" ? "" :
                        <div className="welcome-board">
                            Welcome!<br/><br/>
                            As this is a crowdsourced service, we rely on each other to voluntarily share information that helps promote safety and security.<br/><br/>
                            Please <Link className="welcome-board-link" to='/contribution'>upload a contribution</Link> first to access the search service.
                            <br /><br /><br />
                            <p>Note: You can trying to search before uploading a contribution, with input one of this data detail to form:</p>
                            <ul>
                                <li>Seafarer Fullname: {DUMMY.full_name}</li>
                                <li>Seafarer ID: {DUMMY.seafarer_id}</li>
                                <li>NIK: {DUMMY.nik}</li>
                            </ul>
                        </div>}

                    <div className="form-inner">
                        <div className="form-header">Enter the data of an individual seafarer to search for them.</div>
                        <div className="form-group">
                            <label htmlFor="fullname">SEAFARER FULL NAME</label>
                            <input
                                type="text"
                                name="fullname"
                                id="fullname"
                                className="uppercase"
                                onChange={e => this.fullname = e.target.value}
                            />
                            {(nameError) ? ( <div className="error">{nameError}</div>) : ""}
                        </div>
                    </div>
                    <div className="form-inner">
                        {(uniqueIdError) ?
                        <div className="form-header-error">Input at least one of the following:</div> :
                        <div className="form-header">Input at least one of the following:</div>}
                        <div className="form-group">
                            <label>SEAFARER ID</label>
                            <input
                                type="text"
                                name="seafarerId"
                                id="seafarerId"
                                onChange={e => this.seafarerId = e.target.value}
                            />
                            {(seafarerError) ? ( <div className="error">{seafarerError}</div>) : ""}
                        </div>
                        <div className="form-group">
                            <label>NIK</label>
                            <input
                                type="text"
                                name="nik"
                                id="nik"
                                onChange={e => this.nik = e.target.value}
                            />
                            {(nikError) ? ( <div className="error">{nikError}</div>) : ""}
                        </div>
                    </div>
                    <input
                        className="button-primary"
                        type="submit"
                        value="Run Search"
                    />
                    <div className="bottom-filler"/>
                </form>
        );
    }
};