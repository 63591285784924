import React, { Component } from 'react';
import CollapsingComponent from '.././common/CollapsingComponent'

export default class ActivationConfirmationComponent extends Component {

    state = {
        agree: false
    }

    handleContinue = e => {
        e.preventDefault();
        if (this.validateContinue()) {
            this.props.onSubmit()
        }
    }

    validateContinue = () => {
        const { agree } = this.state;
        let error = 0;

        let agreeError = '';
        if (!agree) {
            agreeError = 'You must agree with our Terms of Use and Privacy Policy before continuing'
            error++;
        }

        this.setState({ ...this.state, agreeError })

        return !error
    }

    onCheckboxChanged = () => {
        this.setState({
            ...this.state,
            agree: !this.state.agree
        })
    }

    render() {
        const { agree, agreeError } = this.state;

        return (
            <form onSubmit={this.handleContinue}>
                <div className="form-inner">
                    <div className="form-title-left">Activate your account</div>
                    <div className="form-header lato">
                        Please read these terms of use carefully before using this website. These terms of use govern your access to and use of this website. By accessing or using this website, you and the entity you are associated with or authorized to represent confirm your agreement to be bound by the terms of use.
                    </div>
                    <CollapsingComponent
                        title="Terms of Use"
                    >
                        <div className="terms">
                        Please read these terms of use carefully before using this website. These terms of use govern your access to and use of this website. By accessing or using this website, you and the entity you are associated with or authorized to represent confirm your agreement to be bound by the terms of use.<br/><br/>
                        These terms of use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Seafarer Background Check (“Company”, “we”, “us” or “our”), concerning your access to and use of this website and service as well as any other service or application linked or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood and agreed to be bound by all of these terms of use.<br/><br/>
                        Supplemental terms of use are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these terms of use of use at any time for any reason. You waive any right to receive specific notice of each such change.<br/><br/>
                        The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or which would subject us to any registration requirement within such jurisdiction or country. The Site is intended for users 18 years of age or above. We reserve the right to withdraw, cancel, modify or suspend any services at any time. We reserve the right to refuse access or disable user accounts with no notification. Any purchases or payments made are nonrefundable.<br/><br/>
                        All information is collated from users and is presented on an as-is and as-available basis. The website makes no representations or warranties of any kind, express or implied, as to the operation of the site or the information, content, or accuracy or completeness of information or content, included on this site. To the full extent permissible by applicable law we disclaim all warranties. This site may include inaccuracies, mistakes, omissions, or typographical errors. We do not warrant that the content will be uninterrupted or error free.<br/><br/>
                        To the maximum extent permitted by law, in no event will be liable for any damages of any kind arising from the use of this site, including, but not limited to, direct, indirect, incidental or consequential, punitive, exemplary, special or consequential damages. To the maximum extent permitted by law, our total liability to the user for any damages (regardless of the foundation for the action) shall not exceed in the aggregate the amount of fees actually paid by the user and received by the website during the month immediately preceding the act allegedly giving rise to our liability.<br/><br/>
                        You agree to defend, indemnify, and hold us harmless, from and against any loss, damage, liability, claim, or demand, including any and all attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your contributions; (2) use of the Site; (3) any information obtained from the Site; (4) breach of these terms of use of use; (5) any breach of your representations and warranties set forth in these terms of use of use; or (6) your violation of the rights of a third party. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification.<br/><br/>
                        As a user of the Site, you agree not to engage in, facilitate, or support any of the following activities in any way:<br/><br/></div>
                        <ol className="terms-numbering">
                            <li className="terms">Use our site, information or services to do or share anything that is unlawful, misleading, discriminatory or fraudulent, or infringes or breaches someone else’s rights.</li>
                            <li className="terms">Upload viruses or malicious code, or do anything that could disable, overburden or impair the proper working or appearance of the site.</li>
                            <li className="terms">Access or collect data from our site using automated means or attempt to access data that you do not have explicit written permission to access.</li>
                            <li className="terms">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
                            <li className="terms">Use any information obtained from the Site in order to harass, abuse or harm another person.</li>
                            <li className="terms">Use the Site or any information obtained from the services in a manner inconsistent with any applicable laws or regulations.</li>
                            <li className="terms">Attempt to impersonate another user or person or use the username of another user, or sell or otherwise transfer your profile.</li>
                        </ol>
                        <div className="terms">
                        <br/>By adding, uploading or posting your information or contribution to any part of the Site, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, transferable, royalty-free, worldwide right and license to host, use, copy, reproduce, disclose, sell, store, and distribute such contributions for any purpose. We are not liable for any statements or representations in your contributions provided by you in any area of the Site.<br/><br/>
                        These terms of use of use shall be governed by and defined following the laws of SINGAPORE. Seafarer Background Check and yourself irrevocably consent that the courts of SINGAPORE shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms. Any dispute arising out of or in connection with this agreement or the Site shall be referred to the Singapore International Arbitration Centre (SIAC). The parties agree that any arbitration shall be limited to the Parties individually.<br/><br/>
                        If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these terms of use of use and does not affect the validity and enforceability of any remaining provisions.<br/><br/><br/>
                        </div>
                    </CollapsingComponent>
                    <CollapsingComponent
                        title="Privacy Policy"
                    >
                        <div className="terms">
                            This privacy policy applies to all information collected through our Site or services. When you sign up for this service, you provide certain information voluntarily, including your name, email address, company, and any other information you give us. This service follows a standard procedure of using log files which log visitors when they visit websites. All hosting companies do this as a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. We may process or share your data with affiliates, subsidiaries, business partners, and other users. We may transfer, store and process your information in countries other than your own. We keep your information for as long as necessary to fulfill the purposes of the service. We do not knowingly collect data from or market to those under 18 years of age. You may terminate your account at any time, and we will deactivate or delete your account from our databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with investigations, enforce our Terms of Use and/or comply with applicable legal requirements. We will update this notice as necessary to stay compliant with relevant laws.
                        </div>
                    </CollapsingComponent>
                    <div className="form-group-checkbox">
                        <input
                            type="checkbox"
                            name="agree"
                            id="agree"
                            checked={agree}
                            onChange={e => this.onCheckboxChanged()}
                        /><label for="agree">I have read, understand and agree to the Terms of Use and Privacy Policy.</label>
                    </div>
                    {(agreeError) ? ( <div className="error">{agreeError}</div>) : ""}
                </div>
                <input className="button-primary" type="submit" value="Next" disabled={agree ? false : true} />
                <div className="bottom-filler" />
            </form>
        )
    }
}