import React, { Component } from 'react';

export default class CollapsingComponent extends Component {
    state = {
        expand: false
    }

    onHeaderClicked = () => {
        this.setState({
            expand: !this.state.expand
        })
    }

    render() {
        const { expand } = this.state;

        return (
            <div>
                <div className={`collapsing-header ${expand ? '' : 'bottom-radius'}`} onClick={this.onHeaderClicked}>
                    <label className="bold">{this.props.title}</label>
                    <i className={`icon fas ${expand ? 'fa-chevron-down' : 'fa-chevron-right'}`} />
                </div>
                <div className={`collapsing-text lato ${expand ? 'visible' : 'invisible'}`}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}