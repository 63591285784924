export default function ContributionDetail ({contribution, actionHandler, backHandler, downloadHandler}) {  

    const getLogoClassName = contribution => {
        if (contribution.is_approved == null) return "user-icon yellow"
        if (contribution.is_approved) return "user-icon green"
        return "user-icon red"
    }

    const getLogo = contribution => {
        if (contribution.is_approved == null) return "fas fa-exclamation"
        if (contribution.is_approved) return "fas fa-check"
        return "fas fa-times"
    }

    const {is_approved, num_of_entries, company_name, num_of_individuals, categories} = contribution;

    return (
        <>
            <div className='user-entry even'>
                <div className="user-entry-data">
                    <label className="user-label">{num_of_entries} entries</label>
                    <label className="user-label bold">{company_name}</label>
                </div>
                <div className={getLogoClassName(contribution)}>
                    <i className={getLogo(contribution)}/>
                </div> 
            </div>  
            <div className='contribution-detail'>
                 Summary Of Data Uploaded<br/><br/>
                 {num_of_entries} entries uploaded in total<br/>
                 {num_of_individuals} individuals<br/><br/>
            </div>  
            <div className='contribution-category-list'>      
                {categories.map((category) => 
                <div className='contribution-category'>
                    <div className="contribution-detail-category-left">{category.category_name}</div>
                    <div className="contribution-detail-category-right">{category.count}</div>
                </div>)}
            </div> 
            <button className="button-mini-secondary" onClick={() => downloadHandler(contribution)}>
                Click to download the file
            </button>
            {!is_approved ? 
            <div className='category-button-area'>
                <button className='button-ok' onClick={() => actionHandler({contribution, actionType:'approve'})}>APPROVE</button>
                <button className='button-error' onClick={() => actionHandler({contribution, actionType:'reject'})}>REJECT</button>
            </div> : ""}
            <button className="button-secondary" onClick={() => backHandler()}>
                Back to Contribution List
            </button>
        </>
    )
}


