const RegisterProgressStep = ({ step = 4, activeStep = 1 }) => {
  return (
    <div className="d-flex items-center justify-content-around position-relative mb-30">
      {
        [...Array(step).keys()].map(i => (
          <div key={i} className={`rounded-circle bg-primary step ${i + 1 > activeStep ? 'inactive' : ''}`} style={{ width: '20px', height: '20px' }}>
          </div>
        ))
      }
      <div className="connecting-line" />
    </div>
  )
}

export default RegisterProgressStep;