
export default function CompanyEntry({ id, index, isActive, title, subtitle, actionHandler, email }) {

    const getLogoClassName = () => {
        if (isActive == null) return "user-icon yellow"
        if (isActive == 1) return "user-icon green"
        return "user-icon red"
    }

    const getLogo = () => {
        if (isActive == null) return "fas fa-exclamation"
        if (isActive == 1) return "fas fa-check"
        return "fas fa-times"
    }

    return (
        <div
            className={(index % 2) == 0 ? 'user-entry even' : 'user-entry odd'}
            onClick={() => actionHandler ? actionHandler(id) : null}
        >
            <div className="user-entry-data">
                <label className="user-label bold lato">{title}</label>
                <label className="user-label lato">{subtitle}</label>
                {email ? <label className="user-label lato">{email}</label> : null}
            </div>
            <div className={getLogoClassName()}>
                <i className={getLogo()} />
            </div>
        </div>
    );
};