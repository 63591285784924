export default function UserDetail ({user, actionHandler, backHandler}) {  

    const getLogoClassName = user => {
        if (!user.is_approved) return "user-icon yellow"
        if (user.is_enabled) return "user-icon green"
        return "user-icon red"
    }

    const getLogo = user => {
        if (!user.is_approved) return "fas fa-exclamation"
        if (user.is_enabled) return "fas fa-check"
        return "fas fa-times"
    }

    return (
        <>
            <div className="user-result-header">
                {user.role === 'basic' ? 'BASIC USER' : 'CONTRIBUTOR'}
            </div>
            <div className='user-entry even'>
                <div className="user-entry-data">
                    <label className="user-label">{user.fullname}</label>
                    <label className="user-label bold">{user.company}</label>
                </div>
                <div className={getLogoClassName(user)}>
                    <i className={getLogo(user)}/>
                </div> 
            </div>  
            <div className='user-entry bg-white'>
                <div className="user-entry-data">
                    <label className="user-label">Job Title / Role</label>
                    <label className="user-label">{user.job_title}</label>
                </div>           
            </div> 
            <div className='user-entry bg-white'>
                <div className="user-entry-data">
                    <label className="user-label">Email</label>
                    <label className="user-label data-email">{user.email}</label>
                </div>           
            </div>
            {!user.is_approved ? 
            <div className='button-area'>
                <button className='button-ok' onClick={() => actionHandler({user, actionType:'approve'})}>APPROVE</button>
                <button className='button-error' onClick={() => actionHandler({user, actionType:'reject'})}>REJECT</button>
            </div> : 
            <div className='button-area'>
                {user.is_enabled ? 
                <button className='button-error' onClick={() => actionHandler({user, actionType:'disable'})}>Disable</button> :
                <button className='button-ok' onClick={() => actionHandler({user, actionType:'enable'})}>Enable</button>}
            </div>}
            <button className="button-secondary" onClick={() => backHandler()}>
                Back to User List
            </button>
        </>
    )
}


