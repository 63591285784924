import React, { Component } from 'react';
import axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import WarningComponent from './../common/WarningComponent';
import '../common/semantic.css'
import RegisterProgressStep from './RegisterProgressStep';

export default class SectionTwoComponent extends Component {

    state = {
        jobTitle: "",
        fullName: "",
        emailAddress: "",
        jobTitleError: "",
        fullNameError: "",
        emailAddressError: "",
        jobTitleSelections: [
            {
                key: 1,
                text: 'Business Owner',
                value: 'Business Owner'
            },
            {
                key: 2,
                text: 'CEO / Managing Director',
                value: 'CEO / Managing Director'
            },
            {
                key: 3,
                text: 'COO or other C-level',
                value: 'COO or other C-level'
            },
            {
                key: 4,
                text: 'Director / Vice President',
                value: 'Director / Vice President'
            },
            {
                key: 5,
                text: 'None of the above',
                value: 'None of the above'
            }
        ]
    }

    handleContinue = e => {
        e.preventDefault();
        if (this.validateContinue()) {
            const { companyId } = this.props;
            const { jobTitle, fullName, emailAddress } = this.state;

            const request = {
                company_id: companyId,
                job_title: jobTitle.trim(),
                full_name: fullName.trim(),
                email_address: emailAddress.trim(),
                created_by: 'WEB'
            }

            axios.post('v1/company/section_2', request)
                .then(res => {
                    this.setState({ ...this.state, companyId: res.data.company_id })
                    this.props.continueHandler(this.state);
                }).catch(err => alert(err?.response?.data?.error || err.message))

        }
    }

    handleToggleButton = role => {
        this.setState({ ...this.state, role })
    }

    validateContinue = () => {
        const { jobTitle, fullName, emailAddress } = this.state;
        let error = 0;

        let jobTitleError = "";
        if (!jobTitle || jobTitle.trim().length < 3) {
            jobTitleError = "Job title should at least have 3 letters";
            error++;
        }

        let fullNameError = "";
        if (!fullName || fullName.trim().length < 3) {
            fullNameError = "Name should at least have 3 letters";
            error++;
        } else if (!fullName.match(/^[a-zA-Z\s]+$/)) {
            fullNameError = "Name should not contain any special character or number";
            error++;
        }

        let emailAddressError = "";
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailAddress || !emailAddress.trim()) {
            emailAddressError = "Email address cannot be empty"
            error++;
        } else if (!regex.test(emailAddress)) {
            emailAddressError = "Email address format is wrong"
            error++;
        }

        this.setState({ ...this.state, jobTitleError, fullNameError, emailAddressError });
        if (!error) {
            return true;
        } else {
            return false;
        }
    }


    enableContinue = () => {
        const { jobTitle } = this.state;

        return jobTitle && jobTitle !== 'None of the above';
    }

    isJobTitleNoneOfTheAbove = () => {
        return this.state.jobTitle !== '' && this.state.jobTitle === 'None of the above';
    }

    render() {
        const { fullName, emailAddress, fullNameError, emailAddressError } = this.state;

        return (
            <form onSubmit={this.handleContinue}>
                <div className="form-inner">
                <div className="form-title-left">Register for a new account</div>
                    <RegisterProgressStep activeStep={2} />
                    <div className="form-group">
                        <label>JOB TITLE / POSITION</label>
                        <Dropdown
                            placeholder='SELECT ONE'
                            options={this.state.jobTitleSelections}
                            onChange={e => this.setState({ ...this.state, jobTitle: e.target.textContent })}
                        />
                    </div>
                    <WarningComponent
                        show={this.isJobTitleNoneOfTheAbove()}
                        message={`Sorry, only directors or above may
                        register your company to be able
                        to access this service.`}
                    />
                    <div className={this.enableContinue() ? "form-group" : "form-group-invisible"}>
                        <label>FULL NAME</label>
                        <input type="text" name="fullName" id="fullName"
                            value={fullName}
                            onChange={e => this.setState({ ...this.state, fullName: e.target.value })} />
                        {(fullNameError) ? (<div className="error">{fullNameError}</div>) : ""}
                    </div>

                    <div className={this.enableContinue() ? "form-group" : "form-group-invisible"}>
                        <label>EMAIL ADDRESS</label>
                        <label>Please use your work email address</label>
                        <input type="text" name="emailAddress" id="emailAddress"
                            value={emailAddress}
                            onChange={e => this.setState({ ...this.state, emailAddress: e.target.value })} />
                        {(emailAddressError) ? (<div className="error">{emailAddressError}</div>) : ""}
                    </div>

                </div>
                <input className="button-primary" type="submit" value="Next" disabled={this.enableContinue() ? false : true} />
                <div className="bottom-filler" />
            </form>
        )
    }
}