export default function ContributionOne ({actionHandler, nextHandler}) {  

    return (
        <>
            <div className='contribution-stepguide-disclaimer'>
                As a crowdsourced service, we rely on each other to share information and keep the service going. This service is provided to help promote safety and security.<br/><br/>
            </div>  
            <div className='contribution-stepguide-header'>
                Add your contribution in three easy steps.<br/><br/>
            </div>
            <div className='contribution-stepguide'>
                1. Download the template 
            </div>
            <div className='contribution-stepguide'>
                2. Fill the template with your data 
            </div>
            <div className='contribution-stepguide'>
                3. Upload the file
            </div>
            
            <div className='contribution-step'>
                1.  Download the template
            </div>
            <div className='contribution-step-detail'>
                This Excel file contains headers for your data.
            </div>
            <button className="button-mini-primary" onClick={() => actionHandler()}>
                Download Excel Template
            </button>
            <button className="button-primary" onClick={() => nextHandler()}>
                Next
            </button>
            <div className="bottom-filler"/>
        </>
    )
}


