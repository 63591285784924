import React, { Component } from 'react';
import { Redirect } from 'react-router';
import SectionOne from './register/SectionOneComponent';
import SectionTwo from './register/SectionTwoComponent';
import SectionThree from './register/SectionThreeComponent';
import SectionFour from './register/SectionFourComponent';
import RegisterSuccess from './register/RegisterSuccessComponent';

export default class Register extends Component {

    state = {
        page: 1,
        companyName: "",
        companyNpwp: "",
        companyId: null,
        jobTitle: "",
        fullName: "",
        emailAddress: "",
        email: "",
        password: "",
        rePassword: "",
        isDataVerified: false,
        isTncVerified: false,
        companyNameError: "",
        companyNpwpError: "",
        titleError: "",
        emailError: "",
        passwordError: "",
        rePasswordError: "",
        dataVerifyError: "",
        tncVerifyError: ""
    }

    componentDidMount () {
        this.props.setPageName('Register');
    }

    handleContinue = state => {
        this.setState({ ...state, page: this.state.page + 1 });
    }

    render() {
        if (this.props.state.loggedIn) {
            return <Redirect to={'/'} />
        }
        switch (this.state.page) {
            case 1:
                return <SectionOne continueHandler={this.handleContinue}  />
            case 2:
                return <SectionTwo companyId={this.state.companyId} continueHandler={this.handleContinue}  />
            case 3:
                return <SectionThree companyId={this.state.companyId} continueHandler={this.handleContinue}  />
            case 4:
                return (
                    <SectionFour
                        companyId={this.state.companyId}
                        fullName={this.state.fullName}
                        jobTitle={this.state.jobTitle}
                        companyNpwp={this.state.companyNpwp}
                        companyName={this.state.companyName}
                        emailAddress={this.state.emailAddress}
                        crewingUserEmail={this.state.crewingUserEmail}
                        continueHandler={this.handleContinue}
                    />
                )
            case 5:
                return <RegisterSuccess email={this.state.email}/>
            default:
                return <SectionOne continueHandler={this.handleContinue} />
        }
    }
}