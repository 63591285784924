
import { Component, createContext } from 'react';
import CompaniesPage from './components/Companies';
import ContributionPage from './components/Contribution';
import ContributionListPage from './components/ContributionList';
import Inactive from './components/Inactive';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Register from './components/Register';
import Search from './components/Search';
import User from './components/User';
import Activation from './components/Activation';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import axios from 'axios';
import AppContext from './Context';

export default class App extends Component {

  componentDidMount() {
      console.log('Version 1.0.6')
  }

  state = {
    loggedIn: false,
    role: 'basic',
    is_contributing: false,
    page_name: "Login",
    navbarAction: null,
  }

  setPageName = (page_name) => {
    this.setState(prevState => ({
      ...prevState,
      page_name
    }));
  }


  handleLogin = (email, res) => {
    this.setState({
      ...this.state,
      loggedIn: true,
      user_role: res.user_role,
      crewingUserEmail: email,
      is_contributing: res.have_approved_contribution
    });
  }

  handleLogout = () => {
    axios.defaults.headers.common['Authorization'] = '';
    this.setState(prevState => ({
      ...prevState,
      loggedIn: false,
      user_role: 'basic',
      is_contributing: false}));
  }

  onUploadContributionSuccess = () => {
    this.setState({
      ...this.state,
      is_contributing: true
    })
  }

  render () {
    return (
      <AppContext.Provider value={{
        store: this.state,
        dispatch: (state) => {
          this.setState(state);
        }
      }}>
        <BrowserRouter>
          <div className="App">
            <Navbar state={this.state} logoutHandler={this.handleLogout}/>
            <Switch>
              <Route exact path="/"
                render={props => (
                  <Search {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout}/>
                )}
              />
              <Route exact path="/login"
                render={props => (
                  <Login {...props} setPageName={this.setPageName} state={this.state} loginHandler={this.handleLogin}/>
                )}
              />
              <Route exact path="/register"
                render={props => (
                  <Register {...props} setPageName={this.setPageName} state={this.state} />
                )}
              />
              <Route exact path="/user"
                render={props => (
                  <User {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout}/>
                )}
              />
              <Route exact path="/inactive"
                render={props => (
                  <Inactive {...props} setPageName={this.setPageName} state={this.state} />
                )}
              />
              <Route exact path="/contribution"
                render={props => (
                  <ContributionPage {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout} onUploadContributionSuccess={this.onUploadContributionSuccess} page={0}/>
                )}
              />
              <Route exact path="/contributionList"
                render={props => (
                  <ContributionListPage {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout}/>
                )}
              />
              <Route exact path="/crewing-user/activate"
                render={props => (
                  <Activation {...props} setPageName={this.setPageName} state={this.state} />
                )}
              />
              <Route exact path="/users"
                render={props => (
                  <CompaniesPage {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout}/>
                )}
              />
              <Route exact path="/crewing-user/forgot-password"
                render={props => (
                  <ForgotPassword {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout}/>
                )}
              />
              <Route exact path="/crewing-user/password/reset"
                render={props => (
                  <ResetPassword {...props} setPageName={this.setPageName} state={this.state} logoutHandler={this.handleLogout}/>
                )}
              />
            </Switch>
          </div>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}
