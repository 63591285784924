import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Dropdown from './navbar/Dropdown';
import { withRouter } from "react-router";
import AppContext from '../Context';

class Navbar extends Component {
    static contextType = AppContext;
    state = {
        dropdown: false,
        prevLocation: "/",
        prevPageName: ""
    }

    handleClick = () => this.setState({...this.state, dropdown: !this.state.dropdown})

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== this.state.prevLocation || this.props.state.page_name !== this.state.prevPageName ) {
            this.setState({...this.state, dropdown: false, prevLocation: this.props.location.pathname, prevPageName: this.props.state.page_name})
        }
    }

    renderAction() {
        const {store} = this.context;
        if (this.props.state.loggedIn) {
            if (!store?.navbarAction) {
                const { dropdown } = this.state
                return (
                    <li className='nav-item'>
                        {dropdown && <Dropdown role={this.props.state.user_role} dropdownHandler={() => this.setState({...this.state, dropdown: false})} logoutHandler={this.props.logoutHandler}/>}
                        <Link className='nav-links' onClick={this.handleClick}>
                            <i className={dropdown ? 'fas fa-times' : 'fas fa-bars'} />
                        </Link>
                    </li>
                );
            }

            return store?.navbarAction;
        }

        return null;
    }

    render () {

        return (
        <>
            <nav className="navbar">
                <ul className='nav-menu'>
                    {this.props.location.pathname === '/register' && <li className='nav-item' key='logout'>
                        <Link
                            className='nav-links-text'
                            to='/login'
                            onClick={() => this.props.logoutHandler()}>
                            LOGIN
                        </Link>
                    </li>}
                    {this.props.state.loggedIn && <li className='nav-item' key='logout'>
                        <Link
                            className='nav-links-text'
                            to='/login'
                            onClick={() => this.props.logoutHandler()}>
                            LOG OUT
                        </Link>
                    </li>}
                    <div className={this.props.state.loggedIn ? "nav-title" : "nav-title extra-left" }>
                        {this.props.state.page_name}
                    </div>
                    {this.renderAction()}
                </ul>
            </nav>
        </>)
    }
}

export default withRouter(Navbar);