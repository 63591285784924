import React, { Component } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import RegisterProgressStep from './RegisterProgressStep';

export default class SectionOneComponent extends Component {

    state = {
        companyName: "",
        companyNpwp: "",
        companyNameError: "",
        companyNpwpError: ""
    }

    handleContinue = e => {
        e.preventDefault();
        if (this.validateContinue()) {
            const { companyName, companyNpwp } = this.state;

            const request = {
                company_name: companyName.trim(),
                company_npwp: companyNpwp.replace(/[.|-]/g, '').trim(),
                created_by: 'WEB'
            }

            axios.post('v1/company/section_1', request)
                .then(res => {
                    this.setState({ ...this.state, companyId: res.data.company_id })
                    this.props.continueHandler(this.state);
                }).catch(err => alert(err?.response?.data?.error || err.message))
        }
    }

    handleToggleButton = role => {
        this.setState({ ...this.state, role })
    }

    validateContinue = () => {
        const { companyName, companyNpwp } = this.state;
        let error = 0;

        let companyNameError = "";
        if (!companyName || companyName.trim().length < 3) {
            companyNameError = "Company name should at least have 3 letters";
            error++;
        } else if (!companyName.match(/^[a-zA-Z\s]+$/)) {
            companyNameError = "Company name should not contain any special character or number";
            error++;
        }

        let companyNpwpError = "";
        if (!companyNpwp || !companyNpwp.trim()) {
            companyNpwpError = "Company NPWP should not be empty";
            error++;
        }

        this.setState({ ...this.state, companyNameError, companyNpwpError });
        if (!error) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { companyName, companyNpwp, companyNameError, companyNpwpError } = this.state;

        return (
            <form onSubmit={this.handleContinue}>
                <div className="form-inner">
                <div className="form-title-left">Register for a new account</div>
                    <RegisterProgressStep />
                    <div className="form-group">
                        <label>COMPANY NAME</label>
                        <input type="text" name="companyName" id="companyName"
                            value={companyName}
                            onChange={e => this.setState({ ...this.state, companyName: e.target.value })} />
                        {(companyNameError) ? (<div className="error">{companyNameError}</div>) : ""}
                    </div>

                    <div className="form-group">
                        <label>COMPANY NPWP</label>
                        <InputMask
                            mask="99.999.999.9-999.999"
                            maskChar=" "
                            value={companyNpwp}
                            onChange={e => this.setState({ ...this.state, companyNpwp: e.target.value })}
                        >
                            {() => (
                                <input type="text" name="companyNpwp" id="companyNpwp" />
                            )}
                        </InputMask>
                        {(companyNpwpError) ? (<div className="error">{companyNpwpError}</div>) : ""}
                    </div>

                </div>
                <input className="button-primary" type="submit" value="Next" />
                <div className="bottom-filler" />
            </form>
        )
    }
}