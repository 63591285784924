
export default function RegisterSuccess({ email }) {
    return (
        <form>
            <div className="form-inner">
                <div className="form-title-left">Registration Complete</div>
            </div>
            <div className='information-layout'>
                <p className="lato">You have successfully registered your company for an account. You and your crewing user will receive an email within three days to activate the account.</p>

                <p className="lato">If you do not receive an email within three days, please let us know at <a class="mailto" href="mailto:seafarercollective@gmail.com">seafarercollective@gmail.com</a>.</p>
                <div className="bottom-filler" />
            </div>
        </form>
    );
};