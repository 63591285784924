import Entry from "./EntryComponent";

export default function Companies({ companies, actionHandler, showAll }) {

    // sort by last updated date, descending
    companies.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    const pendingCompanies = companies.filter(x => x.is_active === null)
    const notPendingCompanies = companies.filter(x => x.is_active)

    // put the pending companies on top, if its less than 3, put other companies until its 3.
    const companyToShow = showAll ? companies : pendingCompanies.concat.apply(pendingCompanies, notPendingCompanies).slice(0, 3);

    const pendingCount = pendingCompanies.length;

    const getNotificationText = () => {
        if (pendingCount === null) return "no pending registration.";
        if (pendingCount === 1) return "1 pending registration.";
        return pendingCount + " pending registrations.";
    }

    return (
        <>
            <div className='pending-count'>
                <div className='pending-count-content'>
                    You have
                </div>
                <div className='pending-count-content bold'>
                    {getNotificationText()}
                </div>
            </div>
            {companies.length > 0 && <div className="user-result">
                {companyToShow.map((company, index) =>
                    <Entry
                        key={company.id}
                        id={company.id}
                        index={index}
                        company={company}
                        title={company.representative_full_name}
                        subtitle={company.company_name}
                        isActive={company.is_active}
                        actionHandler={actionHandler}
                        email={company.crewing_user_email}
                    />)
                }
            </div>}
            <div className="bottom-filler" />
        </>
    );
}