
const toIncidentDate = dateString => {
    if (!dateString) return "-";
    let date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear();
}

export default function OffenseEntry (props) {

    const {full_name, offenses} = props.offense;
    return (
        <div className="offense-entry">
            <label className="offense-label">NAME</label>
            <label className="offense-value">{full_name}</label>
            {offenses.map(offense => (
                <>
                    <label className="offense-label">TYPE / CATEGORY</label>
                    <label className="offense-value">{offense.category_name}</label>
                </>
            ))}
        </div>
    );
};
